import { Button } from '@components/ButtonV2';
import { UserContext } from '@lib/contexts/UserProvider';
import LoginModal from '@organisms/modals/LoginModal';
import { useRouter } from 'next/router';
import { useContext } from 'react';

export const RegisterLoginModal = ({ children = null }) => {
  const [_, { refetch }] = useContext(UserContext);
  const router = useRouter();
  const { paymentType, redirectTo, redirect_to, to } = router.query;
  const redirectURL = (to || redirectTo || redirect_to) as string;

  return (
    <LoginModal
      noRegister
      callBack={(closeModal) => {
        refetch();
        closeModal();
        if (redirectURL) {
          window.location.href = redirectURL;
        } else if (paymentType) {
          router.push({
            pathname: `/${router.query.lang}/subscription/select`,
            query: { paymentType },
          });
        } else {
          router.push(`/${router.query.lang}/videos/genres/all`);
        }
      }}
    >
      {(openModal) => (
        <Button onClick={openModal} link>
          {children}
        </Button>
      )}
    </LoginModal>
  );
};
