import { useContext } from 'react';
import { useRouter } from 'next/router';
import LanguageContext from '@lib/contexts/languageContext';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from 'src/ui/select';
import { LANGUAGES } from '@lib/constants';

export const LanguageDropdown = () => {
  const lang = useContext(LanguageContext);
  const router = useRouter();

  const onChange = (lang) => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        lang,
      },
    });
  };

  const currentLanguage = LANGUAGES.find((language) => language.lang === lang);

  return (
    <Select defaultValue={lang} onValueChange={onChange}>
      <SelectTrigger className="w-[180px] text-primary font-medium gap-1 justify-end border-none [&>svg]:opacity-1">
        <SelectValue>{currentLanguage.trans}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {LANGUAGES.map((language) => (
          <SelectItem key={language.lang} value={language.lang}>
            {language.trans}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
