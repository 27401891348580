import { cn } from '@lib/tw';

export const RegisterImage = ({ className = '', ...props }) => {
  if (props.src === undefined) {
    return (
      <div
        className={cn(
          'rounded w-full object-cover aspect-[3/1] bg-neutral-100',
          className
        )}
      ></div>
    );
  }
  return (
    <img
      className={cn('rounded w-full object-cover bg-neutral-100', className)}
      {...props}
      src={props.src || '/static/register/registration_generic_se_en.jpg'}
      alt=""
      onError={e => {
        (e.target as HTMLImageElement).src =
          '/static/register/registration_generic_se_en.jpg';
      }}
    />
  );
};
