import { useContext, useState } from 'react';
import { ArrowRight, InfoIcon } from 'lucide-react';
import { Trans } from '@lingui/react';
import { Body, Header, Modal } from '@organisms/modals/base';
import { UserContext } from '@lib/contexts/UserProvider';
import { FunnelKind } from '@gql/generated';
import { RegisterImage } from './RegisterImage';
import { t } from '@lingui/macro';
import { cn } from '@lib/tw';

export const SeeWhatsIncludedInPlay = ({ className = '' }) => {
  const [currentUser] = useContext(UserContext);
  const [open, setOpen] = useState(false);

  if (
    currentUser?.id &&
    [
      FunnelKind['Course'],
      FunnelKind['BundlePaid'],
      FunnelKind['LivePaid'],
      FunnelKind['B2hFar'],
      FunnelKind['LiveExternal'],
    ].includes(currentUser?.finalFunnel || currentUser?.funnel)
  ) {
    return null;
  }

  return (
    <>
      <button
        className={cn(
          'flex items-center justify-center rounded bg-[rgb(236,241,246)] text-primary font-semibold w-full py-4 border-none mt-2 pl-4 pr-3',
          className
        )}
        onClick={() => setOpen(true)}
      >
        <InfoIcon className="size-4 mr-1" />{' '}
        <Trans id="register_v2.get_started.whats_include_play" />
        <ArrowRight
          className="arrow-icon size-5 ml-auto mr-0"
          strokeWidth={1}
        />
      </button>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <Header
          className="border-b border-b-neutral-200 !py-5"
          handleClose={() => setOpen(false)}
        >
          <p className="text-xl font-medium m-0">
            <Trans id="register_v2.get_started.whats_include_play_modal.title" />
          </p>
        </Header>
        <Body>
          <RegisterImage
            className="aspect-[3/1.2]"
            src="/static/register/2321056.jpg"
          />
          <p className="text-sm md:text-base font-medium">
            <Trans id="register_v2.get_started.whats_include_play_modal.desc" />
          </p>
          <ul
            className="grid grid-cols-2 items-start gap-x-7 gap-y-3 list-none py-4 px-0 m-0 font-medium text-sm [&_li]:flex [&_li]:gap-2 [&_li]:items-baseline [&_li]:before:content-[''] [&_li]:before:inline-block [&_li]:before:size-2 [&_li]:before:bg-primary [&_li]:before:rounded-full"
            dangerouslySetInnerHTML={{
              __html: t`register_v2.get_started.whats_include_play_modal.list_items`,
            }}
          ></ul>
        </Body>
      </Modal>
    </>
  );
};
