import React from 'react';
import Styled from 'styled-components';
import messageStripe from '@components/MessageStripe';

const MessageStripe = Styled(messageStripe)`
  .code {
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const CodeFoundBanner = ({ className = '', show = false, children }) => {
  if (show) {
    return (
      <MessageStripe
        className={className}
        bg="#D9E8E7"
        color="#242424"
        closable={false}
      >
        {children}
      </MessageStripe>
    );
  }

  return null;
};

export default CodeFoundBanner;
