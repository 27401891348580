import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import MessageStripe from '@components/MessageStripe';
import { getCodeFromReturnUrl } from '@lib/utils';
import CodeFoundBanner from '@organisms/stripeBanners/CodeFoundBanner';
import { t } from '@lingui/macro';

const Banner = styled(MessageStripe)``;

export const TrialBanners = ({
  campaignCode,
  // courseCookies,
}) => {
  const router = useRouter();

  const returnUrlCode =
    getCodeFromReturnUrl(router.query?.to) || router.query.code;

  return (
    <>
      {campaignCode ? (
        <CodeFoundBanner show={!!campaignCode}>
          <Trans
            id="registration.campaign_code_applied"
            values={{
              code: campaignCode,
            }}
            components={[<span key={1} className="code" />]}
          />
        </CodeFoundBanner>
      ) : (
        <CodeFoundBanner show={!!returnUrlCode}>
          <span
            dangerouslySetInnerHTML={{
              __html: t({
                id: 'register_page.discount_code_found',
                values: { code: `<b>${returnUrlCode}</b>` },
              }),
            }}
          ></span>
        </CodeFoundBanner>
      )}

      {/* {courseCookies?.['courseTitle'] ? (
        <Banner bg="#F0ECDF" color="#242424" closable={false}>
          {courseCookies['courseAccessCode'] ? (
            <Trans
              id="registration.course.access_code_banner"
              values={{
                courseName: courseCookies['courseTitle'],
                code: courseCookies['courseAccessCode'],
              }}
              components={[<span key="0" style={{ fontWeight: 500 }} />]}
            />
          ) : (
            <Trans id="registration.course.banner" />
          )}
        </Banner>
      ) : null} */}
    </>
  );
};
