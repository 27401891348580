import styled, { createGlobalStyle } from 'styled-components';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import { Flex } from '@components/layout/Grid';
import { H2 } from '@components/typography/Headings';
import { ReviewsSlider } from '@organisms/ReviewSlider';
import { WellnessRegisterCard } from '@organisms/WellnessRegisterCard';

const GlobalStyle = createGlobalStyle`
  #__next {
    overflow-x: unset;
  }
`;

const LeftContainer = styled(RegisterFormTemplate.LeftContainer)`
  display: none;
  max-height: 100vh;
  position: sticky;
  top: 0;
  justify-content: center;
  background-color: white;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: flex;
    min-height: 100vh;
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1) 100%
      ),
      url('/static/register/registration_side_panel.jpg');
    background-position: left center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 95%;
  padding: 20px 20px 60px;
  box-sizing: content-box;
`;

export const Title = styled(H2)`
  max-width: 80%;
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    margin-top: 40px;
  }
`;

const RegisterLeftContent = () => {
  return (
    <LeftContainer>
      <GlobalStyle />
      {/* <Content> */}
      {/* <WellnessRegisterCard />
        <Flex flex={1} />
        <ReviewsSlider /> */}
      {/* </Content> */}
    </LeftContainer>
  );
};

export default RegisterLeftContent;
