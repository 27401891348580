import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { Input, InputProps } from './input';

const className =
  'absolute right-3 top-2/4 size-5 -translate-y-2/4 opacity-60 cursor-pointer';
export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const [visible, setVisible] = useState(false);

    return (
      <div className="relative flex">
        <Input ref={ref} {...props} type={visible ? 'text' : 'password'} />
        {visible ? (
          <Eye className={className} onClick={() => setVisible(!visible)} />
        ) : (
          <EyeOff className={className} onClick={() => setVisible(!visible)} />
        )}
      </div>
    );
  }
);
